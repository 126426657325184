// messageGenerator.js

// Static success messages without dynamic content
const successMessages = {
    short: [
        `Great start! 🎉 You've completed your workout and have a {streak} day streak! Keep going!`,
        `Nice job! 🌟 You've nailed a {streak} day streak! You're off to a strong start!`,
        `Well done! 👏 You've completed your workout and are on a {streak} day streak! Awesome work!`
    ],
    medium: [
        `Fantastic! 🥳 You've finished your workout and reached a {streak} day streak! Keep pushing!`,
        `Awesome! 🚀 You've completed your workout and now have a {streak} day streak! You're making great progress!`,
        `Great work! 💪 You've powered through your workout and hit a {streak} day streak! You're on the right track!`
    ],
    long: [
        `Incredible! 🌟 You've crushed your workout and reached a {streak} day streak! You're unstoppable!`,
        `Superb! 🎈 You've completed your workout and are rocking a {streak} day streak! You're a superstar!`,
        `Amazing! 🎯 You've finished your workout and scored a {streak} day streak! You're doing fantastic!`
    ]
};

// Function to return a formatted random message based on streak length
export function getSuccessMessage(streak) {
    const messageList = 
        streak <= 3 ? successMessages.short :
        streak <= 7 ? successMessages.medium : 
        successMessages.long;
    
    const randomMessage = messageList[Math.floor(Math.random() * messageList.length)];
    
    return randomMessage.replace('{streak}', streak);
}

export function getStreakSymbols(streak) {
    const symbols = {
        365: '🚀',  // 1 year
        100: '💯',  // 100 days
        50: '🏆',   // 50 days
        10: '🏅',   // 10 days
        1: '💪'     // 1 day
    };
    
    let streakString = '';

    // Sort the entries by milestone in descending order
    const sortedSymbols = Object.entries(symbols).sort((a, b) => b[0] - a[0]);

    for (const [milestone, symbol] of sortedSymbols) {
        const milestoneValue = Number(milestone);  
        const count = Math.floor(streak / milestoneValue);
        
        if (count > 0) {
            streakString += symbol.repeat(count) + ' ';  
            streak %= milestoneValue;  
        }
    }

    return streakString.trim();
}

const posts = {
    oneDay: [
        "Today's challenge? Crushed it. #HIITGenius",
        "One day down, feeling great. #HIITGenius",
        "Pushed through, no turning back. #HIITGenius"
    ],
    twoToFiveDays: [
        "Getting stronger every day. #HIITGenius",
        "Another step forward. #HIITGenius",
        "Feeling unstoppable. #HIITGenius",
        "On the right track. #HIITGenius",
        "Momentum is building! #HIITGenius"
    ],
    sixToTenDays: [
        "Kept the streak alive! #HIITGenius",
        "Not stopping now. #HIITGenius",
        "Kept the momentum going! #HIITGenius",
        "Making it look easy. #HIITGenius",
        "Streak status: active. #HIITGenius",
        "Consistency is key! #HIITGenius"
    ],
    tenPlusDays: [
        "Another one in the books! #HIITGenius",
        "Feeling unstoppable. #HIITGenius",
        "No stopping me now! #HIITGenius",
        "This streak is fire! #HIITGenius",
        "Still going strong! #HIITGenius",
        "Challenge? What challenge? #HIITGenius",
        "Powered through like a champ. #HIITGenius",
        "Consistency is my game. #HIITGenius",
        "Making every day count. #HIITGenius",
        "Another day, another victory. #HIITGenius",
        "I'm on a roll! #HIITGenius",
        "More progress, more strength. #HIITGenius",
        "Day after day, still crushing it. #HIITGenius",
        "This streak is going places! #HIITGenius",
        "One step closer to my goal. #HIITGenius"
    ]
};

export function generateSocialMediaPost(streak) {
    let selectedPost;

    if (streak === 1) {
        selectedPost = posts.oneDay[Math.floor(Math.random() * posts.oneDay.length)];
    } else if (streak <= 5) {
        selectedPost = posts.twoToFiveDays[Math.floor(Math.random() * posts.twoToFiveDays.length)];
    } else if (streak <= 10) {
        selectedPost = posts.sixToTenDays[Math.floor(Math.random() * posts.sixToTenDays.length)];
    } else {
        selectedPost = posts.tenPlusDays[Math.floor(Math.random() * posts.tenPlusDays.length)];
    }

    const streakSymbols = getStreakSymbols(streak);
    const socialMediaPost = `${streakSymbols}\n${selectedPost}`;

    return socialMediaPost;
}